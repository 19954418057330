import React from "react"
import technologyHeader from "../../../assets/images/products/netversys/technology_header.svg"
import { Link } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"
import CallToAction from "../CallToAction"
import { StaticImage } from "gatsby-plugin-image"


const Technology = () => {
  return (
    <>
      <section className="services-details-area pt-100 pb-70">
        <div className="container mt-5">
          <Row>
            <Col lg={8} md={12} style={{ marginBottom: "2em" }}>
              <div className="services-details-image">
                <img src={technologyHeader} alt="Technologie" />
              </div>

              <h1 className={"pt-3"}>Innovative Technologien</h1>
              <p>
                Unsere Softwareprodukte – das Warehouse Management System NETSTORSYS und unser
                Multicarrier-Versandsystem NETVERSYS – basieren auf aktuellen Technologien für App-Programmierung
                mit
                dem Ziel, Anwendungen schneller und kostengünstiger zu entwickeln, auf kundenindividuelle
                Anforderungen
                anzupassen, zu erweitern und zu aktualisieren.
              </p>

              <h2 className={"pt-3"}>Unser Tech Stack für smoothe Logistik</h2>
              <p>
                Verlassen Sie sich auf einen optimalen <b>Tech Stack</b>: Ein Set von Technologien also mit dem
                Ziel,
                die
                Nutzung, Integration, Entwicklung und laufende Optimierung Ihres neuen Warehouse Mangement Systems
                oder Multicarrier-Versandsystems so <b>nutzerfreundlich, passgenau, schnell und
                kostenfreundlich</b> wie
                möglich zu machen.
              </p>
              <p>
                Wir arbeiten schnell und kundenzentriert. Setzen Programmiersprachen, Frameworks, Datenquellen,
                Entwicklungswerkzeuge, UI/UX-Lösungen, Server und Entwurfsmuster zu Logistiksoftware zusammen, die
                Sie
                in Ihren täglichen Lager- und Transport-Prozessen maximal unkompliziert, effizient und budgettreu
                einsetzen. Machen Sie Effizienz zu Ihrer Routine!
              </p>
            </Col>
          </Row>
        </div>
      </section>
      <section className="technology-section bg-fffbf5">
        <Container>
          <span className="mb-2 text-primary" style={{ fontWeight: "bolder" }}>Hält Schritt, schafft Vorsprung</span>
          <h2>Mehr Tech, mehr Vorteile</h2>
          <div className="configurations-section">
            <ul className={"fa-ul"}>
              <Row style={{ paddingLeft: 0 }}>
                <Col xs={12} lg={6}>
                  <Link to="/deep-dive">
                    <li>
                    <span className={"fa-li"}><i style={{ fontSize: "2em" }}
                                                 className={"bx bx-check text-primary"} /></span>
                      <span className="bullet-point-title">
                     Hohe Testabdeckung</span>
                      Unser Lagerverwaltungssystem wird durch automatisierte Tests im Hintergrund schneller von
                      möglichen
                      Fehlern bereinigt und optimiert, läuft schnell und zuverlässig beim Kunden.
                    </li>
                  </Link>
                  <Link
                    to="/angular-framework/">
                    <li>
                    <span className={"fa-li"}><i style={{ fontSize: "2em" }}
                                                 className={"bx bx-check text-primary"} /></span>
                      <span className="bullet-point-title">Wiederverwendbare Komponenten </span>
                      Effizienter Code lässt sich dank des Angular Frameworks immer wieder verwenden und beliebig
                      einbinden, statt ihn mehrfach neu zu schreiben. So etablieren sich Neuentwicklungen als
                      leistungsstarke Standards, von denen alle Kunden profitieren.
                    </li>
                  </Link>
                  <Link
                    to={"/modulare-software/"}>
                    <li>
                    <span className={"fa-li"}><i style={{ fontSize: "2em" }}
                                                 className={"bx bx-check text-primary"} /></span>
                      <span className="bullet-point-title">Modulare Software</span>
                      Die Software wird in unabhängige Module aufgeteilt, die hinzugefügt, entfernt und bearbeitet
                      werden
                      können, ohne das Gesamtsystem zu beeinträchtigen. Software wird flexibler, wartbarer, skalierbarer
                      und effizienter in der Entwicklung, und kann schnell auf neue Anforderungen angepasst werden.
                    </li>
                  </Link>
                </Col>
                <Col xs={12} lg={{ span: 6 }}>
                  <Link
                    to="/api-programmierschnittstelle/">
                    <li>
                    <span className={"fa-li"}><i style={{ fontSize: "2em" }}
                                                 className={"bx bx-check text-primary"} /></span>
                      <span className="bullet-point-title">Schnittstellen </span>
                      NETSTORSYS lässt sich dank flexibler Schnittstellen wie REST und SOAP nahtlos mit beliebigen
                      Vorsystemen verknüpfen, um jedes ERP- oder Warenwirtschaftssystem um effiziente Lagerverwaltung zu
                      erweitern.
                    </li>
                  </Link>
                  <Link to="/releasefaehigkeit/">
                    <li>
                    <span className={"fa-li"}><i style={{ fontSize: "2em" }}
                                                 className={"bx bx-check text-primary"} /></span>
                      <span className="bullet-point-title">Releasefähigkeit </span>
                      Individuelle Anpassungen und Erweiterungen bleiben auch nach dem Einspielen von Updates oder einer
                      neuen Version (Release) erhalten und funktionieren weiterhin. Per Release-Notes bleiben unsere
                      Kunden über den Stand Ihres LVS auf dem Laufenden.
                    </li>
                  </Link>
                  <Link to="/on-premises/">
                    <li>
                    <span className={"fa-li"}><i style={{ fontSize: "2em" }}
                                                 className={"bx bx-check text-primary"} /></span>
                      <span className="bullet-point-title"> Cloud & On-Premises</span>
                      Sie entscheiden, ob Ihr Lagerverwaltungssystem remote auf einem Hosting-Server oder einer Cloud,
                      installiert ist, oder lokal auf Servern in Ihrem Unternehmen vor Ort, also On-Premises. NETSTORSYS
                      kann beides problemlos.
                    </li>
                  </Link>
                </Col>
              </Row>
            </ul>
          </div>
          <Row className="section-button">
            <Link to={"/deep-dive/"}>
              <button type="button" className="btn btn-outline-primary">
                <i className="bx bx-chevron-right" />
                Mehr Deep-Dives
              </button>
            </Link>
          </Row>
        </Container>
      </section>
      <section className="technology-section bg-f1f8fb">
        <Container>
          <Row>
            <div className="configurations-section">
              <h2>Einfache Deployments</h2>
              <ul className={"fa-ul"}>
                <li>
                   <span className={"fa-li"}><i style={{ fontSize: "2em" }}
                                                className={"bx bx-refresh text-primary"} /></span>
                  Wir setzen die Programmiersprachen Java und Kotlin ein. <b>Java</b> ist eine der beliebtesten
                  Entwicklungssprachen weltweit. Sie ist objektorientiert und dank der Java Virtual Machine (JVM)
                  plattformunabhängig und vielseitig einsetzbar, von Android-Apps bis zu Enterprise-Anwendungen. Java
                  ist
                  bekannt für seine Stabilität und seine große Entwickler-Community.
                </li>
                <li>
                     <span className={"fa-li"}><i style={{ fontSize: "2em" }}
                                                  className={"bx bx-refresh text-primary"} /></span>
                  <b>Kotlin</b> ist eine moderne, mit Java kompatible Programmiersprache, die auch auf der JVM läuft.
                  Sie
                  wird
                  oft als “natürliche” Weiterentwicklung von Java betrachtet, bietet viele moderne Features und eine
                  schlankere Syntax. Kotlin ist besonders beliebt bei der Entwicklung für das Android-Betriebssystem.
                </li>
                <li>
                     <span className={"fa-li"}><i style={{ fontSize: "2em" }}
                                                  className={"bx bx-refresh text-primary"} /></span>
                  Beide Sprachen können gut zusammenarbeiten und werden in der Softwareentwicklung häufig eingesetzt.
                  Hinzu kommt, dass die Java-Laufzeitumgebung auf allen relevanten CPU- bzw. Hardwareplattformen
                  verbreitet ist. Das bedeutet, dass sich unser WMS und unsere Versandsoftware auf fast jedem am Markt
                  verfügbaren Server einsetzen lässt.
                </li>
                <li>
                     <span className={"fa-li"}><i style={{ fontSize: "2em" }}
                                                  className={"bx bx-refresh text-primary"} /></span>
                  Dank innovativem Deployment sind nahezu keine Abhängigkeiten nötig, um NETSTORSYS und NETVERSYS zu
                  installieren. Wir nutzen einen integrierten Application Server (Tomcat), können damit das
                  bereitgestellte Paket sofort ausführen und dauerhaft in Betrieb nehmen. Möglich ist auch, diesen
                  Vorgang
                  in den normalen Bootprozess des jeweiligen Betriebssystems einzubinden.
                </li>
                <li>
                     <span className={"fa-li"}><i style={{ fontSize: "2em" }}
                                                  className={"bx bx-refresh text-primary"} /></span>
                  Ein weiterer Schritt, Deployments zu vereinfachen, ist <b>Docker</b> und <b>Kubernetes</b>. Die
                  Open-Source-Plattform
                  Docker ermöglicht es, Software mithilfe von Containern schneller, effizienter und zuverlässiger in
                  vielen Umgebungen bereitzustellen. Kubernetes ist ein Open-Source-System, um die Container-Verwaltung
                  zu
                  automatisieren. Alle Abhängigkeiten werden von vorkonfigurierten Containern bereitgestellt, die direkt
                  miteinander kommunizieren und ein sofort einsatzfähiges System bieten. Die Produktivität steigt, die
                  Kosten sinken
                </li>
              </ul>
            </div>

          </Row>
          <Row className="section-button">
            <Link to={"/docker/"}>
              <button type="button" className="btn btn-outline-primary">
                <i className="bx bx-chevron-right" />
                Mehr zu Docker
              </button>
            </Link>
          </Row>
        </Container>
      </section>
      <section className="technology-section bg-fffbf5">
        <Container>
          <Row>
            <Col md={12} lg={7}>
              <h2>Webapplikation: Performance auf allen Geräten</h2>
              <p>
                Webbasierte Anwendungen haben sich etabliert. Denn die Software wird zentral auf einem lokalen Server
                oder in der Cloud gehostet, gewartet und weiterentwickelt. Seit Jahren verfolgen wir den Ansatz
                mächtiger browserbasierter Benutzeroberflächen. Egal ob Workstation oder Desktop, Smartphone, Tablet
                oder Scanner: Nutzer greifen schnell mit verschiedenen Endgeräten von überall auf die jeweils neueste
                Version von NETVERSYS und NETSTORSYS zu. Das ermöglicht zum Beispiel die papierlose Kommissionierung,
                verhindert Bedienfehler, falsch übertragene Daten und senkt erheblich Kosten und Aufwand für die
                IT-Abteilung Ihres Unternehmens.
              </p>
              <p>
                Moderne Browsertechnologien bieten für alle Produkte interaktive, intuitive Benutzeroberflächen, die die
                Anwender mehrsprachig führen und Information übersichtlich und klar in modernen Dashboards anzeigen.
                Kundenspezifische Erweiterungen und Anpassungen, zum Beispiel bestimmte Prozesse, Detailansichten,
                Listen und Sortierungen, lassen sich unkompliziert integrieren, ohne das bestehende System “auf Links”
                drehen zu müssen.
              </p>
              <p>
                Lager-KPIs, Bestände oder den Status von Lieferungen von unterwegs checken, das Stresslevel für die
                IT-Mannschaft senken, die IT-Verwaltung und Instandhaltung auslagern? Kein Problem mit unserer
                browserbasierten Logistiksoftware.
              </p>
            </Col>
            <Col className="section-image-right">
              <StaticImage src="../../../assets/images/products/netversys/frameworks.svg"
                           alt="Einfache Deployments" placeholder="blurred" width={500}>

              </StaticImage>
            </Col>
          </Row>
          <Row className="section-button">
            <Link to={"/vorteile-der-lagerdigitalisierung/"}>
              <button type="button" className="btn btn-outline-primary">
                <i className="bx bx-chevron-right" />
                Mehr zu Lagerdigitalisierung
              </button>
            </Link>
          </Row>
        </Container>
      </section>
      <section className="technology-section ptb-100">
        <Container>
          <Row>
            <Col lg={{ offset: 0, span: 5 }} style={{ display: "flex", alignItems: "center" }}
                 className="section-image-left">
              <StaticImage
                src="../../../assets/images/products/technology/Datenbanken_Lager_web.png"
                alt="Datenbanken"
                placeholder="blurred"
                width={500}
              />
            </Col>
            <Col lg={{ offset: 1, span: 6 }}>
              <h2>Volle Datenbank-Freiheit</h2>
              <p>
                NETSTORSYS und NETVERSYS sind zu 100% datenbankneutral – unser Warehousemanagement System und unser
                Multicarrier-Versandsystem unterstützen alle relevanten relationalen Datenbanken am Markt.
              </p>
              <p>
                Relationale Datenbanken sind eine bewährte Technologie zur Verwaltung strukturierter Daten. Diese können
                effizient abgerufen und kombiniert werden. Relationale Datenbanken bieten viele Vorteile, wie
                Datenintegrität, Flexibilität und Skalierbarkeit.
              </p>
              <p>
                Weil bei relationalen Datenbanken keine neuen Datenbanklizenzen notwendig sind, bleiben die Kosten
                gering. Beispiele für solche Datenbanken sind MySQL, PostgreSQL, Oracle oder der Microsoft SQL Server.
                Wir empfehlen den Einsatz von MariaDB. Diese Datenbank steht unter der Open-Source-Lizenz, ist
                kostenfrei und erfüllt höchste technische Ansprüche.
              </p>
            </Col>
          </Row>
          <Row className="section-button">
            <Link to={"/relationale-datenbanken/"}>
              <button type="button" className="btn btn-outline-primary">
                <i className="bx bx-chevron-right" />
                Mehr zu Datenbanken
              </button>
            </Link>
          </Row>
        </Container>
      </section>
      <section className="services-area bg-f1f8fb ptb-100">
        <Container>
          <Row>
            <Col lg={11}>
              <h4 style={{ marginBottom: "1em" }}>
                Noch offene Fragen?</h4>
              <p>
                Fragen zu den Technologien und zum Leistungspektrum unserer Softwareprodukte NETSTORSYS und NETVERSYS?
                Zu den Möglichkeiten, cloudnative kostengünstige Logistiksoftware höchster Funktionalität und
                Flexiblität in Ihren Prozessen zu integrieren und zu einem entscheidenden Erfolgsfaktor zu machen?
              </p>
              <p>
                Sprechen Sie uns jederzeit an.
              </p>
              <div style={{ marginTop: "2em" }}>
                <CallToAction text={"Jetzt beraten lassen"} link={"/kontakt"}></CallToAction>
              </div>
            </Col>
          </Row>
        </Container>

      </section>
    </>
  )
}

export default Technology
