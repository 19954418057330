import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Technology from "../components/ProductDetails/netversys/Technology"
import Seo from "../components/App/Seo"

const TechnologyPage = () => {
  return (
    <Layout>
      <Seo title={"Fortschrittliche Technologie ergibt gute Prozesse im Lager"}
           description={"Technologisch vorne dabei im Bereich Warehouse Management. " +
             "Software die Sie begeistern wird."}
      />
      <Navbar />
      <Technology />
      <Footer />
    </Layout>
  )
}

export default TechnologyPage
